@tailwind base;
@tailwind components;
@tailwind utilities;

/* Define the Montserrat font face */
@font-face {
  font-family: 'Montserrat';
  src: url('/public/Montserrat-Light.ttf') format('truetype'); /* Replace with the correct path to the Montserrat regular font file */
  font-weight: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/public/Montserrat-Bold.ttf') format('truetype'); /* Replace with the correct path to the Montserrat bold font file */
  font-weight: bold;
}

/* Add additional @font-face rules for other font styles/weights if necessary */

/* Apply Montserrat as the default font for the entire document */
body {
  font-family: 'Montserrat', sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #dad5d2;
  color: #a4998c;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  box-sizing: border-box;
}

.container {
  padding: 15px 30% 15px 10%;
}

.header-text{
  margin-top: 10%;
  font-size: 30px;
  color: #514c4a;
}

.colored-text{
  color: #514c4a;
}

/* ---------------about---------------- */

/* hashtags are for id not class */

.row{
  margin-top: 30px;
  display: flex;
  align-items: center; /* Center vertically */
}

.row p{
  margin-left: 20px;
  font-size: 22px;
  color:  #a4998c;
}

.row img{
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 3px solid #514c4a;
}

/* --------------Portfolio/Experience---------------- */
.experienceHeader{
  margin: 0;
  font-size: 50px;
  color: #514c4a;
}

.card {
  border: 2px solid #514c4a;
  border-radius: 10px;
  margin-top: 4%;
  width: 40%;
  background-color: #dad5d2;
}

.card-body {
  padding: 20px;
}

.card-body-content {
  display: flex;
  align-items: center;
}

.card-body-content img {
  border-radius: 50%;
  margin-right: 10px;
}

.card-title {
  font-size: 20px;
  margin: 0;
  color: #514c4a;
  display: inline-block;
}

.card-subtitle {
  font-size: 14px;
  margin: 0;
  color: #a4998c;
}

.card-text {
  margin-top: 10px;
}

ul {
  margin-left: 10px;
  padding: 0;
}

ul li {
  margin-bottom: 5px;
}

.skillCard{
  border: 2px solid #514c4a;
  border-radius: 10px;
  margin-top: 1%;
  width: 49%;
  background-color: #dad5d2;
}

.skill-title {
  font-size: 20px;
  margin: 10px;
  color: #514c4a;
  display: flex;
  align-items: center; /* Centers items vertically */
}

.skill-title svg {
  margin-right: 8px;
}

.pLink {
  transition: color .5s;
}

.pLink:hover {
  color: #d8b26e;
}



/* ---Contact--- */
.contact-left{
  flex-basis: 35%;
}

.contact-right{
  flex-basis: 60%;
}

.contact-left p{
  margin-top: 30px;
}

.contact-left p svg{
  color: #514c4a;
  margin-right: 15px;

}

.btn.btn2{
  display: inline-block;
  background: #d8b26e;
}

.contact-right form{
  width: 100%;
}
form input, form textarea{
  width: 100%;
  border: 0;
  outline: none;
  background: #dad5d2;
  padding: 15px;
  margin: 15px;
  color: #a4998c;
  font-size: 18px;
  border-radius: 6px;
  border: 2px solid #514c4a;
}

form .btn2{
  padding: 14px 60px;
  font-size: 18px;
  margin-top: 20px;
  margin-left: 15px;
  cursor: pointer;
}
#msg{
  color: #514c4a;
  margin-top: 10px;
  display: block;
}
